<template>
  <div>
    <div
      class="fixed top-0 left-0 w-full h-full flex items-center justify-center"
    >
      <div class="bg-white max-w-lg p-6 relative rounded-xl w-11/12">
        <div class="absolute right-2 top-2">
          <button
            type="button"
            @click="toggleProfileSettingsModal"
            class="text-gray-500 hover:text-gray-700"
          >
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div class="mb-4">
          <h3 class="font-semibold leading-6 text-lg text-shdarkblue-500">
            Profile Settings
          </h3>
        </div>
        <div class="flex flex-col gap-6">
          <div class="flex items-center justify-between">
            <p class="leading-6 text-base text-shdarkblue-500">First Name</p>
            <input
              autocomplete="off"
              v-model="modalFormData.firstName"
              class="bg-gray-50 border-b border-gray-200 outline-none p-2 rounded-lg text-shdarkblue-500 w-72"
            />
          </div>
          <div class="flex items-center justify-between">
            <p class="leading-6 text-base text-shdarkblue-500">Last Name</p>
            <input
              autocomplete="off"
              v-model="modalFormData.lastName"
              class="bg-gray-50 border-b border-gray-200 outline-none p-2 rounded-lg text-shdarkblue-500 w-72"
            />
          </div>
          <!-- <div class="flex items-center justify-between">
            <p class="leading-6 text-base text-shdarkblue-500">Username</p>
            <input
              autocomplete="off"
              v-model="modalFormData.username"
              class="bg-gray-50 border-b border-gray-200 outline-none p-2 rounded-lg text-shdarkblue-500 w-72"
            />
          </div> -->
          <div class="flex items-center justify-between">
            <p class="leading-6 text-base text-shdarkblue-500">Email</p>
            <input
              autocomplete="off"
              v-model="modalFormData.email"
              class="bg-gray-50 border-b border-gray-200 outline-none p-2 rounded-lg text-shdarkblue-500 w-72"
            />
          </div>
          <div class="flex items-center justify-between">
            <p class="leading-6 text-base text-shdarkblue-500">Password</p>
            <input
              autocomplete="off"
              v-model="modalFormData.password"
              class="bg-gray-50 border-b border-gray-200 outline-none p-2 rounded-lg text-shdarkblue-500 w-72"
              type="password"
            />
          </div>
        </div>
        <div class="flex gap-4 justify-end mt-6">
          <button
            type="button"
            @click="update(modalFormData)"
            class="bg-shgreen-500 hover:bg-shgreen-400 px-5 py-2 rounded-lg text-white"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      previousEditData: {},
      modalFormData: {},
    };
  },

  async created() {
    await this.fetchProfile();
  },

  methods: {
    async fetchProfile() {
      try {
        const { data } = await this.$http("admin/info");

        const admin = data?.data;

        this.previousEditData = { ...admin };
        this.modalFormData = { ...admin };
      } catch (error) {}
    },

    toggleProfileSettingsModal() {
      this.$emit("toggleProfileSettingsModal");
    },

    async update(data) {
      const oldData = this.previousEditData;
      let newData = {};

      const newDataObject = Object.keys(data);

      newDataObject.forEach(function (newDataKey) {
        if (oldData[newDataKey] != data[newDataKey]) {
          newData[newDataKey] = data[newDataKey];
        }
      });

      try {
        // newData.userName = newData.username || oldData.username;

        const req = await this.$http.put(`admin/${data._id}`, newData);

        this.$emit("setAlert", req.data.message);
        await new Promise((r) => setTimeout(r, 2000));
        this.$emit("setAlert", null);

        this.$emit("toggleProfileSettingsModal");
      } catch (error) {
        this.$emit("setAlert", error.response.data.message);
        await new Promise((r) => setTimeout(r, 2000));
        this.$emit("setAlert", null);
      }
    },
  },
};
</script>

<style></style>
