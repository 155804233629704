import { createApp } from "vue";
import { store, axios } from "@/fetchAndStore";

import App from "./App.vue";
import router from "./router";
import { DateTime } from "luxon";

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

import "./assets/main.css";

const app = createApp(App);

app.config.globalProperties.$http = axios;
app.config.globalProperties.$dt = DateTime;

app.use(router);
app.use(store);

app.component("select2", Multiselect);

app.mount("#app");
