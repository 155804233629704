<template>
  <div>
    <TheNavbar @toggleProfileSettingsModal="isProfileSettingsModalOpen = !isProfileSettingsModalOpen" />
    <ProfileSettingsModal :isProfileSettingsModalOpen="isProfileSettingsModalOpen"
      @toggleProfileSettingsModal="isProfileSettingsModalOpen = !isProfileSettingsModalOpen" @setAlert="setAlert"
      v-if="isProfileSettingsModalOpen" />

    <div :id="isProfileSettingsModalOpen ? 'blurContent' : null">
      <router-view />
    </div>

    <div v-if="alert" class="fixed flex h-full items-end justify-center left-0 top-0 w-full">
      <div class="bg-shgreen-500 bottom-5 max-w-lg px-6 py-4 relative rounded-xl shadow-gray-200 shadow-xl w-11/12">
        <div class="flex gap-6 justify-center">
          <p class="text-sm text-white">{{ alert }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar.vue'
import ProfileSettingsModal from '@/components/ProfileSettingsModal.vue'
export default {
  components: { TheNavbar, ProfileSettingsModal },

  data() {
    return {
      isProfileSettingsModalOpen: false,
      alert: null
    }
  },

  methods: {
    setAlert(message) {
      this.alert = message
    }
  }
}
</script>

<style lang="scss">
#blurContent {
  position: relative;
  z-index: -1;
  filter: blur(8px);
}
</style>
