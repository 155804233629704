<template>
  <div>
    <div v-if="isModalOpen">
      <div
        v-if="modalType == 'delete'"
        class="fixed top-0 left-0 w-full h-full flex items-center justify-center"
      >
        <div class="bg-white max-w-lg p-6 relative rounded-xl w-11/12">
          <div class="absolute right-2 top-2">
            <button
              @click="isModalOpen = false"
              type="button"
              class="text-gray-500 hover:text-gray-700"
            >
              <svg
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="mb-4">
            <h3
              class="font-semibold leading-6 text-center text-lg text-shdarkblue-500"
            >
              Delete Admin
            </h3>
          </div>
          <div class="flex gap-6 justify-center">
            <p class="leading-6 text-base text-shdarkblue-500">
              Are you sure that you want to delete this Admin?
            </p>
          </div>
          <div class="flex gap-4 justify-between mt-6">
            <button
              @click="isModalOpen = false"
              type="button"
              class="bg-white border border-gray-200 hover:bg-gray-50 px-5 py-2 rounded-lg text-shdarkblue-500"
            >
              Cancel
            </button>
            <button
              @click="destroyAdmin(modalFormData._id)"
              type="button"
              class="bg-shgreen-500 hover:bg-shgreen-400 px-5 py-2 rounded-lg text-white"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="fixed top-0 left-0 w-full h-full flex items-center justify-center"
      >
        <div class="bg-white max-w-lg p-6 relative rounded-xl w-11/12">
          <div class="absolute right-2 top-2">
            <button
              @click="isModalOpen = false"
              type="button"
              class="text-gray-500 hover:text-gray-700"
            >
              <svg
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="mb-4">
            <h3
              v-if="modalType == 'add'"
              class="font-semibold leading-6 text-lg text-shdarkblue-500"
            >
              Add Admin
            </h3>
            <h3
              v-if="modalType == 'edit'"
              class="font-semibold leading-6 text-lg text-shdarkblue-500"
            >
              Edit Admin
            </h3>
          </div>
          <div class="flex flex-col gap-6">
            <div class="flex items-center justify-between">
              <p class="leading-6 text-base text-shdarkblue-500">First Name</p>
              <input
                autocomplete="off"
                v-model="modalFormData.firstName"
                placeholder="Lisa"
                class="bg-gray-50 border-b border-gray-200 outline-none p-2 rounded-lg text-shdarkblue-500 w-72"
              />
            </div>
            <div class="flex items-center justify-between">
              <p class="leading-6 text-base text-shdarkblue-500">Last Name</p>
              <input
                autocomplete="off"
                v-model="modalFormData.lastName"
                placeholder="Mona"
                class="bg-gray-50 border-b border-gray-200 outline-none p-2 rounded-lg text-shdarkblue-500 w-72"
              />
            </div>
            <!-- <div class="flex items-center justify-between">
              <p class="leading-6 text-base text-shdarkblue-500">Username</p>
              <input
                autocomplete="off"
                v-model="modalFormData.username"
                placeholder="lisamonna"
                class="bg-gray-50 border-b border-gray-200 outline-none p-2 rounded-lg text-shdarkblue-500 w-72"
              />
            </div> -->
            <div class="flex items-center justify-between">
              <p class="leading-6 text-base text-shdarkblue-500">Email</p>
              <input
                autocomplete="off"
                v-model="modalFormData.email"
                placeholder="lisa@monna.com"
                class="bg-gray-50 border-b border-gray-200 outline-none p-2 rounded-lg text-shdarkblue-500 w-72"
              />
            </div>
            <div class="flex items-center justify-between">
              <p class="leading-6 text-base text-shdarkblue-500">Password</p>
              <input
                autocomplete="off"
                v-model="modalFormData.password"
                placeholder="********"
                class="bg-gray-50 border-b border-gray-200 outline-none p-2 rounded-lg text-shdarkblue-500 w-72"
                type="password"
              />
            </div>
            <div class="flex items-center justify-between">
              <p class="leading-6 text-base text-shdarkblue-500">
                Confirm Password
              </p>
              <input
                autocomplete="off"
                v-model="modalFormData.confirmPassword"
                placeholder="********"
                class="bg-gray-50 border-b border-gray-200 outline-none p-2 rounded-lg text-shdarkblue-500 w-72"
                type="password"
              />
            </div>
            <div class="flex items-center justify-between">
              <p class="leading-6 text-base text-shdarkblue-500">Status</p>
              <select
                v-model="modalFormData.disabled"
                class="bg-white border-0 border-gray-200 font-light outline-none pr-8 px-4 py-2 rounded-lg shadow-gray-100 shadow-lg text-shdarkblue-600 w-36"
              >
                <option :value="false">Active</option>
                <option :value="true">Disabled</option>
              </select>
            </div>
          </div>
          <div class="flex gap-4 justify-between mt-6">
            <button
              v-if="modalType == 'add'"
              @click="createAdmin(modalFormData)"
              type="button"
              class="bg-shgreen-500 hover:bg-shgreen-400 px-5 py-2 rounded-lg text-white"
              style="width: 100%"
            >
              Create Admin
            </button>

            <button
              v-if="modalType == 'edit'"
              @click="deleteAdmin"
              type="button"
              class="bg-shred-500 hover:bg-shred-400 px-5 py-2 rounded-lg text-white"
            >
              Delete
            </button>
            <button
              v-if="modalType == 'edit'"
              @click="updateAdmin(modalFormData)"
              type="button"
              class="bg-shgreen-500 hover:bg-shgreen-400 px-5 py-2 rounded-lg text-white"
            >
              Update Admin
            </button>
          </div>
        </div>
      </div>
    </div>
    <div :id="isModalOpen ? 'blurContent' : null">
      <div class="container flex justify-between mx-auto p-5">
        <button
          type="button"
          @click="addAdmin"
          class="bg-shgreen-500 hover:bg-shgreen-400 px-5 py-2 rounded-lg text-white"
        >
          Add Admin
        </button>
        <div class="hidden xl:block">
          <div class="flex gap-4">
            <select2
              class="w-48"
              placeholder="Name"
              :show-labels="false"
              :options="filterData.name.options"
              v-model="filterData.name.selected"
              @Select="(op) => filterAdmin('name', op)"
              @Remove="filterAdmin('name', null)"
            />
            <select2
              class="w-48"
              placeholder="Email"
              :show-labels="false"
              :options="filterData.email.options"
              v-model="filterData.email.selected"
              @Select="(op) => filterAdmin('email', op)"
              @Remove="filterAdmin('email', null)"
            />
            <select2
              class="w-48"
              placeholder="Status"
              label="label"
              track-by="value"
              :show-labels="false"
              :searchable="false"
              :options="[
                {
                  label: 'Active',
                  value: false,
                },
                {
                  label: 'Disabled',
                  value: true,
                },
              ]"
              v-model="filterData.disabled.selected"
              @Select="(op) => filterAdmin('disabled', op.value)"
              @Remove="filterAdmin('disabled', null)"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.label }}
              </template>
            </select2>
          </div>
        </div>
      </div>
      <div class="container flex font-thin justify-between mx-auto px-5 py-2">
        <div class="flex gap-4">
          <span class="w-52">Name</span
          ><span class="hidden w-56 sm:block">Email</span
          ><span class="hidden w-28 lg:block">Registered</span
          ><span class="hidden w-48 lg:block">Last Login</span
          ><span class="hidden w-32 lg:block">Last IP</span>
        </div>
        <div class="flex gap-4"><span>Status</span></div>
      </div>
      <div
        v-for="admin in filteredAdmins"
        :key="admin._id"
        class="border-gray-100 border-t container cursor-pointer flex hover:bg-gray-50 items-center justify-between mx-auto px-5 py-3 rounded-lg"
      >
        <div class="flex gap-4" @click="editAdmin(admin)">
          <span class="w-52">{{ admin.firstName + " " + admin.lastName }}</span>
          <span class="hidden w-56 sm:block">{{ admin.email }}</span>
          <span class="hidden w-28 lg:block">
            {{ parseDate(admin.createdAt) }}
          </span>
          <span class="hidden w-48 lg:block">
            {{ parseDateTime(admin.lastLogin) }}
          </span>
          <span class="hidden w-32 lg:block">{{ admin.lastIp }}</span>
        </div>
        <div class="flex gap-4">
          <select
            v-model="admin.disabled"
            @change="updateAdmin({ _id: admin._id, disabled: admin.disabled })"
            class="bg-white border-0 border-gray-200 font-light outline-none pr-8 px-4 py-2 rounded-lg shadow-gray-100 shadow-lg text-shdarkblue-600 w-36"
          >
            <option :value="false">Active</option>
            <option :value="true">Disabled</option>
          </select>
        </div>
      </div>
    </div>

    <div
      v-if="alert"
      class="fixed flex h-full items-end justify-center left-0 top-0 w-full"
    >
      <div
        class="bg-shgreen-500 bottom-5 max-w-lg px-6 py-4 relative rounded-xl shadow-gray-200 shadow-xl w-11/12"
      >
        <div class="flex gap-6 justify-center">
          <p class="text-sm text-white">{{ alert }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      admins: [],
      filteredAdmins: [],

      isModalOpen: false,
      modalType: null, //add / edit

      previousEditData: {},

      modalFormData: {
        _id: null,

        firstName: null,
        lastName: null,
        // username: null,
        email: null,
        createdAt: null,
        lastLogin: null,
        lastIp: null,
        disabled: false,
      },

      alert: null,

      filterData: {
        name: {
          selected: null,
          options: [],
        },
        email: {
          selected: null,
          options: [],
        },
        disabled: {
          selected: false,
          options: [false, true],
        },
      },
    };
  },

  async created() {
    await this.fetchAdmins();
  },

  methods: {
    parseDate(date) {
      if (date) {
        return this.$dt.fromISO(date).toLocaleString();
      }
    },

    parseDateTime(date) {
      if (date) {
        return this.$dt.fromISO(date).toLocaleString(this.$dt.DATETIME_MED);
      }
    },

    async filterAdmin(key, value) {
      if (value === null) {
        this.filteredAdmins = this.admins;
        return 0;
      }

      if (key == "name") {
        this.filteredAdmins = this.admins.filter(
          (admin) => admin.firstName + " " + admin.lastName == value
        );
        return 0;
      }

      if (value !== null) {
        this.filteredAdmins = this.admins.filter(
          (admin) => admin[key] == value
        );
        return 0;
      }
    },

    addAdmin() {
      this.modalFormData = {
        _id: null,

        firstName: null,
        lastName: null,
        // username: null,
        email: null,
        createdAt: null,
        lastLogin: null,
        lastIp: null,
        disabled: false,
      };

      this.isModalOpen = true;
      this.modalType = "add";
    },

    editAdmin(data) {
      this.isModalOpen = true;
      this.modalType = "edit";
      this.previousEditData = { ...data };
      this.modalFormData = { ...data };
    },

    deleteAdmin() {
      this.isModalOpen = true;
      this.modalType = "delete";
    },

    async fetchAdmins() {
      try {
        const { data } = await this.$http.get("admin");
        let admins = data?.data;

        this.admins = admins;
        this.filteredAdmins = admins;

        let filterData = {
          name: {
            selected: null,
            options: [],
          },
          email: {
            selected: null,
            options: [],
          },
          disabled: {
            selected: false,
            options: [false, true],
          },
        };

        // to add options to select filter
        admins.forEach(function (admin) {
          filterData.name.options.push(admin.firstName + " " + admin.lastName);
          filterData.email.options.push(admin.email);
        });

        // to remove duplicate
        filterData.name.options = [...new Set(filterData.name.options)];
        filterData.email.options = [...new Set(filterData.email.options)];

        this.filterData = filterData;
      } catch (error) {
        this.alert = error.response?.data?.message ?? "Error";
      }

      await new Promise((r) => setTimeout(r, 2000));
      this.alert = null;
    },

    async createAdmin(data) {
      data.language = "EN";
      // data.userName = data.username;

      try {
        const req = await this.$http.post(`admin`, data);

        this.alert = req.data.message;
      } catch (error) {
        this.alert = error.response.data.message;
      }

      this.isModalOpen = false;
      await this.fetchAdmins();
      await new Promise((r) => setTimeout(r, 2000));
      this.alert = null;
    },

    async updateAdmin(data) {
      const oldData = this.previousEditData;
      let newData = {};

      const newDataObject = Object.keys(data);

      newDataObject.forEach(function (newDataKey) {
        if (oldData[newDataKey] != data[newDataKey]) {
          newData[newDataKey] = data[newDataKey];
        }
      });

      try {
        const req = await this.$http.put(`admin/${data._id}`, newData);

        this.alert = req.data.message;
      } catch (error) {
        this.alert = error.response.data.message;
      }

      this.isModalOpen = false;
      await this.fetchAdmins();
      await new Promise((r) => setTimeout(r, 2000));
      this.alert = null;
    },

    async destroyAdmin(id) {
      try {
        const req = await this.$http.delete(`admin/delete/${id}`);

        this.alert = req.data.message;
      } catch (error) {
        this.alert = error.response.data.message;
      }

      this.isModalOpen = false;
      await this.fetchAdmins();
      await new Promise((r) => setTimeout(r, 2000));
      this.alert = null;
    },
  },
};
</script>

<style scoped>
.filters {
  margin: 3px;
}
</style>
