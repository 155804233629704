import { createStore } from "vuex";
import axios from "axios";
import router from "@/router";

let token = localStorage.getItem("accessToken");

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["access-token"] = token;

const store = createStore({
  state() {
    return {
      isLoggedIn: false,
      adminInfo: {},
      errorMessage: null,
    };
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },

    getAdminInfo(state) {
      return state.adminInfo;
    },

    errorMessage(state) {
      return state.errorMessage;
    },

    token(state) {
      return state.adminInfo.accessToken;
    },
  },

  actions: {
    async login(context, payload) {
      try {
        const { data } = await axios.post("admin/login", {
          email: payload.email,
          password: payload.password,
        });

        localStorage.setItem("accessToken", data.data.accessToken);

        await context.dispatch("setAdminInfo");

        await router.push({ name: "home" });
      } catch (error) {
        console.error(error);
        context.commit("setErrorMessage", "Wrong Credentials");
      }
    },

    async logout() {
      localStorage.removeItem("accessToken");
    },

    async setAdminInfo(context) {
      try {
        axios.defaults.headers.common = {
          "access-token": `${localStorage.getItem("accessToken")}`,
        };
        const { data } = await axios.get("admin/info");

        context.commit("setAdminInfo", data?.data);
        context.commit("setLoggedInStatus", true);
      } catch (error) {
        context.commit("setAdminInfo", {});
        context.commit("setLoggedInStatus", false);
      }
    },
  },

  mutations: {
    setLoggedInStatus(state, status) {
      state.isLoggedIn = status;
    },

    setAdminInfo(state, adminInfo) {
      state.adminInfo = adminInfo;
    },

    async setErrorMessage(state, errorMessage) {
      state.errorMessage = errorMessage;
      await new Promise((r) => setTimeout(r, 2000));
      state.errorMessage = null;
    },
  },
});

export { axios, store };
