import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import { createRouter, createWebHistory } from "vue-router";
import { store } from "@/fetchAndStore";

import LoginView from "../views/Auth/LoginView.vue";
import HomeView from "../views/HomeView.vue";
import AdminsView from "../views/AdminsView.vue";
import UsersView from "../views/UsersView.vue";
import RoomsView from "../views/RoomsView.vue";

const routes = [
    {
        path: "/",
        redirect: "/home",
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: {
            middleware: guest,
        },
    },
    {
        path: "/home",
        name: "home",
        component: UsersView,
        meta: {
            middleware: auth,
        },
    },
    {
        path: "/admins",
        name: "admins",
        component: AdminsView,
        meta: {
            middleware: auth,
        },
    },
    {
        path: "/users",
        name: "users",
        component: UsersView,
        meta: {
            middleware: auth,
        },
    },
    {
        path: "/rooms",
        name: "rooms",
        component: RoomsView,
        meta: {
            middleware: auth,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
            store,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

export default router;
