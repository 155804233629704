<template>
  <div>
    <div class="container flex justify-end mx-auto p-5">
      <div class="hidden xl:block">
        <div class="flex gap-4">
          <select2
            class="w-48"
            placeholder="Room Name"
            :show-labels="false"
            :options="filterData.name.options"
            v-model="filterData.name.selected"
            @Select="(op) => filterRoom('name', op)"
            @Remove="filterRoom('name', null)"
          />
          <select2
            class="w-48"
            placeholder="Owner Name"
            :show-labels="false"
            :options="filterData.owner.name.options"
            v-model="filterData.owner.name.selected"
            @Select="(op) => filterRoom('owner.name', op)"
            @Remove="filterRoom('owner.name', null)"
          />
        </div>
      </div>
    </div>
    <div class="container flex font-thin justify-between mx-auto px-5 py-2">
      <div class="flex gap-4">
        <span class="w-52">Room Name</span>
        <span class="w-52">Module</span>
        <span class="w-52">Room Owner</span>
        <span class="hidden w-40 lg:block">Created</span>
        <span class="hidden w-44 lg:block">Last Updated</span>
      </div>
      <div class="flex gap-4"><span>Status</span></div>
    </div>
    <div
      v-for="room in filteredRooms"
      :key="room._id"
      class="border-gray-100 border-t container cursor-pointer flex hover:bg-gray-50 items-center justify-between mx-auto px-5 py-3 rounded-lg"
    >
      <div class="flex gap-4">
        <span class="w-52">{{ room.name }}</span>
        <span class="w-52">{{ room.type }}</span>
        <span class="hidden w-52 sm:block">{{ parseRoomOwner(room) }}</span>
        <span class="hidden w-40 lg:block">
          {{ parseDateTime(room.createdAt) }}
        </span>
        <span class="hidden w-44 lg:block">
          {{ parseDateTime(room.updatedAt) }}
        </span>
      </div>
      <div class="flex gap-4">
        <select
          v-model="room.disabled"
          @change="updateRoom({ _id: room._id, disabled: room.disabled })"
          class="bg-white border-0 border-gray-200 font-light outline-none pr-8 px-4 py-2 rounded-lg shadow-gray-100 shadow-lg text-shdarkblue-600 w-36"
        >
          <option :value="false">Active</option>
          <option :value="true">Disabled</option>
        </select>
      </div>
    </div>

    <div
      v-if="alert"
      class="fixed flex h-full items-end justify-center left-0 top-0 w-full"
    >
      <div
        class="bg-shgreen-500 bottom-5 max-w-lg px-6 py-4 relative rounded-xl shadow-gray-200 shadow-xl w-11/12"
      >
        <div class="flex gap-6 justify-center">
          <p class="text-sm text-white">{{ alert }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rooms: [],
      filteredRooms: [],

      alert: null,

      filterData: {
        name: {
          selected: null,
          options: [],
        },
        owner: {
          name: {
            selected: null,
            options: [],
          },
        },
      },
    };
  },

  async created() {
    await this.fetchRooms();
  },

  methods: {
    parseDate(date) {
      if (date) {
        return this.$dt.fromISO(date).toLocaleString();
      }
    },

    parseDateTime(date) {
      if (date) {
        return this.$dt.fromISO(date).toLocaleString(this.$dt.DATETIME_MED);
      }
    },

    parseRoomOwner(room) {
      if (room.owner) {
        return room.owner?.firstName + " " + room.owner?.lastName;
      } else {
        return "-";
      }
    },

    async filterRoom(key, value) {
      console.log({ key, value });
      if (!value) {
        this.filteredRooms = this.rooms;
        return 0;
      }

      if (key == "owner.name") {
        this.filteredRooms = this.rooms.filter(
          (room) => room.owner.firstName + " " + room.owner.lastName == value
        );
        return 0;
      }

      if (value) {
        this.filteredRooms = this.rooms.filter((room) => room[key] == value);
        return 0;
      }
    },

    async fetchRooms() {
      try {
        const { data } = await this.$http.get("admin/room");
        let rooms = data.data;

        this.rooms = rooms;
        this.filteredRooms = rooms;

        let filterData = {
          name: {
            selected: null,
            options: [],
          },
          owner: {
            name: {
              selected: null,
              options: [],
            },
          },
        };

        // to add options to select filter
        rooms.forEach(function (room) {
          filterData.name.options.push(room.name);
          filterData.owner.name.options.push(
            room.owner?.firstName + " " + room.owner?.lastName
          );
        });

        // to remove duplicate
        filterData.name.options = [...new Set(filterData.name.options)];
        filterData.owner.name.options = [
          ...new Set(filterData.owner.name.options),
        ];

        this.filterData = filterData;
      } catch (error) {}
    },

    async updateRoom(data) {
      try {
        const req = await this.$http.put(`admin/room/update/${data._id}`, {
          disabled: data.disabled,
        });

        this.alert = req.data.message;
      } catch (error) {
        this.alert = error.response.data.message;
      }

      await this.fetchRooms();
      await new Promise((r) => setTimeout(r, 2000));
      this.alert = null;
    },
  },
};
</script>

<style scoped>
.filters {
  margin: 3px;
}
</style>
