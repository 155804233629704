<template>
    <div>
        {{ getAdminInfo }}
        {{ isLoggedIn }}
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(["isLoggedIn", "getAdminInfo"])
    },
}
</script>

<style>

</style>