<template>
    <div>
        <LoginForm />

        <div v-if="errorMessage" class="fixed flex h-full items-end justify-center left-0 top-0 w-full">
            <div class="bg-shred-500 bottom-5 max-w-lg px-6 py-4 relative rounded-xl shadow-gray-200 shadow-xl w-11/12">
                <div class="flex gap-6 justify-center">
                    <p class="text-sm text-white">Your email or password is wrong. Please try again in 10 minutes or
                        else you will be blocked.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoginForm from '@/components/Auth/LoginForm.vue'
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(["errorMessage"])
    },
    components: { LoginForm },
}
</script>

<!-- <style>
body {
    background-color: #f5f5f5;
}
</style> -->